export const papers = [
  {
    name: 'A bijection between ordered trees and 2-Motzkin paths and its many consequences',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+bijection+between+ordered+trees+and+2-Motzkin+paths+and+its+many+consequences.pdf',
  },
  {
    name: 'A Combinatorial Proof of a Chebyshev Polynomial Identity',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+Combinatorial+Proof+of+a+Chebyshev+Polynomial+Identity.pdf',
  },
  {
    name: 'A Lattice Path Lemma and an Application in Enzyme Kinetics',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+Lattice+Path+Lemma+and+an+Application+in+Enzyme+Kinetics.pdf',
  },
  {
    name: "A Natural Proof of Abel's Identity",
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+Natural+Proof+of+Abels+Identity.pdf',
  },
  {
    name: "A Natural Proof of Abel's Identity\nArs Combinatoria\nVolume 28\nDecember 1980",
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+Natural+Proof+of+Abels+Identity\nArs+Combinatoria\nVolume+28\nDecember+1980.pdf',
  },
  {
    name: "A Short Proof of an Identity of Touchard's Concerning Catalan Numbers",
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+Short+Proof+of+an+Identity+of+Touchards+Concerning+Catalan+Numbers.pdf',
  },
  {
    name: 'A survey of the Fine numbers',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+survey+of+the+Fine+numbers.pdf',
  },
  {
    name: 'A Survey of the Fine numbers',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/A+Survey+of+the+Fine+numbers.pdf',
  },
  {
    name: 'An algebraic structure for Faber polynomials',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/An+algebraic+structure+for+Faber+polynomials.pdf',
  },
  {
    name: 'An Electrical Lemma',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/An+Electrical+Lemma.pdf',
  },
  {
    name: 'Bootstrap Percolation, The Schroder Numbers, and the N-Kings Problem',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Bootstrap+Percolation_+The+Schroder+Numbers_+and+the+N-Kings+Problem.pdf',
  },
  {
    name: 'Bulletin of the Institute of the Combinatorics and its Applications',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Bulletin+of+the+Institute+of+the+Combinatorics+and+its+Applications.pdf',
  },
  {
    name: 'Catalan and Motzkin Probabilities\nCongressus Numerantium 135 (1998)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Catalan+and+Motzkin+Probabilities\nCongressus+Numerantium+135+1998.pdf',
  },
  {
    name: 'Catalan Numbers and "Total Information" Numbers',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Catalan+Numbers+and+Total+Information+Numbers.pdf',
  },
  {
    name: 'Combinatorial interpretations for the Legendre polynomials and the hypergeometric functions',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Combinatorial+interpretations+for+the+Legendre+polynomials+and+the+hypergeometric+functions.pdf',
  },
  {
    name: 'Fibonacci Numbers and Upper Triangular Groups\nA Reprint from The Fibonacci Quarterly\nVolume 14 Number 3\nOctober 1976',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Fibonacci+Numbers+and+Upper+Triangular+Groups\nA+Reprint+from+The+Fibonacci+Quarterly\nVolume+14+Number+3\nOctober+1976.pdf',
  },
  {
    name: 'Generating Function Identities via 2 x 2 Matrices',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Generating+Function+Identities+via+2+x+2+Matrices.pdf',
  },
  {
    name: 'Generating Function Identities via 2x2 Matrices',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Generating+Function+Identities+via+2x2+Matrices.pdf',
  },
  {
    name: 'Generating Function Proofs of Old and New Results in Probability\nCongressus Numerantium 143 (2000)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Generating+Function+Proofs+of+Old+and+New+Results+in+Probability\nCongressus+Numerantium+143+2000.pdf',
  },
  {
    name: 'How To Guess a Generating Function',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/How+To+Guess+a+Generating+Function.pdf',
  },
  {
    name: 'Motzkin Numbers',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Motzkin+Numbers.pdf',
  },
  {
    name: 'Positive Definite Matrices and Catalan Numbers, Revisited\n(A reprint from the Proceedings of the American Mathematical Society)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Positive+Definite+Matrices+and+Catalan+Numbers_+Revisited\nA+reprint+from+the+Proceedings+of+the+American+Mathematical+Society.pdf',
  },
  {
    name: 'Probabilities of the Forbidden',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Probabilities+of+the+Forbidden.pdf',
  },
  {
    name: 'Product-Weighted Lead Codes Revisited',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Product-Weighted+Lead+Codes+Revisited.pdf',
  },
  {
    name: 'Protected Points in Ordered Trees',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Protected+Points+in+Ordered+Trees.pdf',
  },
  {
    name: 'Random Walks and Hills\nCongressus Numerantium 180 (2006)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Random+Walks+and+Hills\nCongressus+Numerantium+180+2006.pdf',
  },
  {
    name: 'Riordan Group Involutions',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Riordan+Group+Involutions.pdf',
  },
  {
    name: 'Runs, Slides, and Moments',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Runs_+Slides_+and+Moments.pdf',
  },
  {
    name: 'Seventeen Catalan Identities',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Seventeen+Catalan+Identities.pdf',
  },
  {
    name: 'Simple Proofs of open problems about the structure of involutions in the Riordan group',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Simple+Proofs+of+open+problems+about+the+structure+of+involutions+in+the+Riordan+group.pdf',
  },
  {
    name: 'Some Generating Function Proofs of Old and New Results in Probability',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Some+Generating+Function+Proofs+of+Old+and+New+Results+in+Probability.pdf',
  },
  {
    name: 'Some Open Questions about Random Walks, Involutions, Limiting Distributions, and Generating Functions',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Some+Open+Questions+about+Random+Walks_+Involutions_+Limiting+Distributions_+and+Generating+Functions.pdf',
  },
  {
    name: 'Some q-analogues of the Schroder numbers arising from combinatorial statistics on lattice paths',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Some+q-analogues+of+the+Schroder+numbers+arising+from+combinatorial+statistics+on+lattice+paths.pdf',
  },
  {
    name: 'The Average is One\nCongressus Numerantium 176 (2005)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Average+is+One\nCongressus+Numerantium+176+2005.pdf',
  },
  {
    name: "The Combinatorics of B' = B3\nCongressus Numerantium 107 (1995)",
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Combinatorics+of+B+=+B3\nCongressus+Numerantium+107+1995.pdf',
  },
  {
    name: "The Combinatorics of M' = 3M3T and T' = T3M\nCongressus Numeratium 186 (2007)",
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Combinatorics+of+M+=+3M3T+and+T+=+T3M\nCongressus+Numeratium+186+2007.pdf',
  },
  {
    name: 'The Cycle of Six\nA Reprint from The Fibonacci Quarterly\nVolume 17 Number 3\nOctober 1979',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Cycle+of+Six\nA+Reprint+from+The+Fibonacci+Quarterly\nVolume+17+Number+3\nOctober+1979.pdf',
  },
  {
    name: 'The Fine numbers refined',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Fine+numbers+refined.pdf',
  },
  {
    name: 'The higher you go, the odder it gets\nCongressus Numerantium 138 (1999)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+higher+you+go_+the+odder+it+gets\nCongressus+Numerantium+138+1999.pdf',
  },
  {
    name: 'The hitting time subgroup, Lucasiewicz paths and Faber polynomials',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+hitting+time+subgroup_+Lucasiewicz+paths+and+Faber+polynomials.pdf',
  },
  {
    name: 'The Noncrossing Descent Matrix is Riordan\nCongressus Numerantium 162 (2003)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Noncrossing+Descent+Matrix+is+Riordan\nCongressus+Numerantium+162+2003.pdf',
  },
  {
    name: 'The Riordan Group ',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Riordan+Group+.pdf',
  },
  {
    name: 'The Riordan Group (original via typewriter)',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/The+Riordan+Group+original+via+typewriter.pdf',
  },
  {
    name: 'Upper Triangular Rings, Ideals, and Catalan Numbers',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Upper+Triangular+Rings_+Ideals_+and+Catalan+Numbers.pdf',
  },
  {
    name: 'Voting blocks, reluctant functions, and a formula of Hurwitz',
    url: 'https://lou-shapiro-papers.s3.amazonaws.com/Voting+blocks_+reluctant+functions_+and+a+formula+of+Hurwitz.pdf',
  },
];
