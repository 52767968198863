import React from 'react';
import * as style from './Header.module.css';
import { DataContext } from '../DataProvider';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { papers } from '../../data';

import styled from 'styled-components';

function Header() {
  const { randomItem, setApiWasRequested, setRequestWasHandled } =
    React.useContext(DataContext);

  function handleClick() {
    console.log('handling click');
    setApiWasRequested(true);
    setRequestWasHandled(false);
  }
  console.log(papers);

  return (
    <>
      <header className={`${style.header} ${true ? style.headerSuccess : ''}`}>
        <h1>Lou Shapiro</h1>
      </header>
      <P>
        Here is a fraction: <InlineMath>{`\\frac{a + b^{c^d}}{2}`}</InlineMath>
      </P>
      <P>Here is a matrix: </P>
      <BlockMath>
        {`
      \\begin{Bmatrix}
      a_{11} & a_{12} & a_{13} \\\\
      a_{21} & a_{22} & a_{23} \\\\
      a_{31} & a_{32} & a_{33}
      \\end{Bmatrix}
      `}
      </BlockMath>

      {/* <Latex displayMode={false}>What is $(3\times 4) \div (5-3)$</Latex> */}
      <Table>
        <Thead>
          <THR>
            <THcell>Paper</THcell>
            <THcell>Link</THcell>
          </THR>
        </Thead>
        <Tbody>
          {papers.map((paperRow) => {
            return (
              <TBR>
                <TBcell>{paperRow.name}</TBcell>
                <TBcell>
                  <A href={paperRow.url}>Link</A>
                </TBcell>
              </TBR>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}

export default Header;

const Button = styled.button`
  padding: 20px;
  background-color: hsl(217, 100%, 61%);
  color: white;
  width: fit-content;
  border-radius: 8px;
  margin: 100px auto;
  border: 2px solid black;
`;

const P = styled.p`
  margin-left: 300px;
`;

const A = styled.a`
  color: blue;

  &:visited {
    color: blue;
  }
`;
const Table = styled.table`
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 100px;
  border-collapse: collapse;
`;
const Thead = styled.thead``;
const THR = styled.tr``;
const TBR = styled.tr``;

const THcell = styled.th`
  border: 1px solid black;
`;

const Tbody = styled.tbody``;
const TBcell = styled.td`
  border: 1px solid black;
  padding: 5px;
`;
